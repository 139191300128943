import React from "react";

import Layout from "src/common/Layout";
import SEO from "src/common/components/seo";
import { graphql } from "gatsby";

const NotFoundPage = ({ data: { contentfulFooter, contentfulHeader } }: { data: any }) => (
  <Layout
    contentfulFooter={contentfulFooter}
    contentfulHeader={contentfulHeader}
  >
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
);

export default NotFoundPage;

export const query = graphql`
query NotFoundPage  ($locale:String!){
    contentfulFooter (node_locale: {eq: $locale}){
    title
    text
    copyright
    columnOneTitle
    columnOneLinks {
      id
      label
      url
      type
    }
    columnTwoTitle
    columnTwoLinks {
      id
      label
      url
      type
    }
    columnThreeTitle
    columnThreeLinks {
      id
      label
      url
      type
    }
    instagramLink
    facebookLink
    pinterestLink
  }
  contentfulHeader (node_locale: {eq: $locale}){
    links {
      id
      label
      url
      type
    }
  }
}
`